<template>
	<div id="body">
		<div class="fatherBox">
			<div class="headerBox">
				<!-- <p @click="$router.go(-1)" class="stulist">学校列表</p><p class="sprit">/</p><p class="StuNameies">{{SchName}}设备列表</p> -->
				<!-- <p @click="$router.go(-1)"  class="stulist">学校列表 / {{SchName}}设备列表</p> -->
				<div class="headerinfos">
					<p @click="$router.go(-1)" class="stulist">学校列表</p>
					<p>/</p>
					<p>{{SchName}}设备列表</p>
				</div>
				<el-button type="success" class="btn" @click="facilityDialog = true">+ 投放设备</el-button>
			</div>
			<div class="query">
				<div class="queryLeft">
					<div style="width: auto;">设备类型：
						<el-select class="select" v-model="serchList.MachineType" size="medium" placeholder="请选择设备类型">
							<el-option v-for="item in facilityListSearch" :key="item.value" :label="item.info"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div style="width: auto;">运行状态：
						<el-select class="select" v-model="serchList.yxtype" size="medium" placeholder="请选择运行状态">
							<el-option v-for="item in yxList" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>

					<el-input class="input" v-model="serchList.mechineName" size="medium"
						placeholder="请输入设备编号"></el-input>
					<!--          <div>地区：
            <el-select class="select" v-model="serchList.region" @change="machineList(serchList,1)" size="medium"
              placeholder="请选择地区">
              <el-option v-for="item in regionList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div>学校：
            <el-select class="select" v-model="serchList.schoolId" @change="machineList(serchList,1)" size="medium"
              placeholder="请选择学校">
              <el-option v-for="item in schoolData" :key="item.id" :label="item.schoolName" :value="item.id">
              </el-option>
            </el-select>
          </div> -->
				</div>
				<div class="queryRight">
					<el-button @click="reset()">重置</el-button>
					<el-button type="primary" @click="search(serchList)">查询</el-button>
				</div>
			</div>

			<el-table :data="facilityInfos" v-loading="loading">
				<el-table-column prop="MechineName" label="设备编号" align="center" width="150"></el-table-column>
				<!-- <el-table-column label="绑定学校" :formatter="BindSchool" align="center"></el-table-column> -->
				<el-table-column label="设备类型" :formatter="machineFilter" width="120" align="center"></el-table-column>
				<!-- 				<el-table-column label="状态" sortable :formatter="Status" align="center"></el-table-column> -->
				<el-table-column label="在线状态" :formatter="onlineStatus" width="80" align="center"></el-table-column>
				<el-table-column label="IP" prop="ip" align="center"></el-table-column>

				<el-table-column label="最后时间" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.onlineTime != '0'"
							style="display: flex;align-items: center;justify-content: center;">
							<div v-if="scope.row.onlineStatus >0">
								<div style="width: 10px;height: 10px;border-radius: 50%;margin-right: 10px;"
									:style="scope.row.onlineStatus == '1' ? 'background: #67C23A;' : 'background: #F56C6C;'">
								</div>
							</div>
							<div v-if="scope.row.onlineStatus >0">{{$util.dateFormat(scope.row.onlineTime)}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="投放时间" :formatter="LaunchTime" align="center"></el-table-column>
				<el-table-column label="绑定时间" :formatter="InsertTime" align="center"></el-table-column>
				<el-table-column label="操作" fixed="right" align="center" width="280">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" plain @click="detail(scope.$index, scope.row)">详情
						</el-button>
						<el-button size="mini" type="primary" plain @click="recycle(scope.$index, scope.row)">回收
						</el-button>
						<el-button v-if="scope.row.MachineType=='10'" size="mini" type="primary" plain
							@click="ipAdmin(scope.row)">IP设置
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="margin-top: 10px;">
				<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
					layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum">
				</el-pagination>
			</div>
		</div>

		<el-drawer title="投放校门读卡器设备" :wrapperClosable="false" :visible.sync="ClasdrawerXmdkq" direction="rtl" size="23%"
			@closed="aaa()">
			<el-input v-model.trim="searchMname" placeholder="输入编号" type="text" style="width:200px;" />
			<el-button type="primary" class="btn" style="margin-left:10px" @click="searchMnameF(10)">搜索</el-button>
			<el-table ref="multipleTable" :data="tableDataXmdkq" tooltip-effect="dark" style="width: 100%"
				@selection-change="handleSelectionChange">
				<el-table-column label="设备信息" width="260">
					<template slot-scope="scope">
						<div class="drawer_List">
							<p>编号：{{ scope.row.MechineName }}</p>
							<p>绑定时间：{{ scope.row.InsertTime.split(' ')[0] }}</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column type="selection" width="55">
				</el-table-column>
			</el-table>
			<div style="margin-top: 20px">
				<el-button type="primary" @click="PutIn(10)" style="width:200px">投放</el-button>
			</div>
		</el-drawer>
		<el-drawer title="投放校门签到设备" :wrapperClosable="false" :visible.sync="ClasdrawerXmqd" direction="rtl" size="23%"
			@closed="aaa()">
			<el-input v-model.trim="searchMname" placeholder="输入编号" type="text" style="width:200px;" />
			<el-button type="primary" class="btn" style="margin-left:10px" @click="searchMnameF(9)">搜索</el-button>
			<el-table ref="multipleTable" :data="tableDataXmqd" tooltip-effect="dark" style="width: 100%"
				@selection-change="handleSelectionChange">
				<el-table-column label="设备信息" width="260">
					<template slot-scope="scope">
						<div class="drawer_List">
							<p>编号：{{ scope.row.MechineName }}</p>
							<p>绑定时间：{{ scope.row.InsertTime ? scope.row.InsertTime.slice(0,19) : '' }}</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column type="selection" width="55">
				</el-table-column>
			</el-table>
			<div style="margin-top: 20px">
				<el-button type="primary" @click="PutIn(9)" style="width:200px">投放</el-button>
			</div>
		</el-drawer>

		<el-drawer title="投放视力检测设备" :wrapperClosable="false" :visible.sync="Clasdrawer" direction="rtl" size="23%"
			@closed="aaa()">
			<el-input v-model.trim="searchMname" placeholder="输入编号" type="text" style="width:200px;" />
			<el-button type="primary" class="btn" style="margin-left:10px" @click="searchMnameF(1)">搜索</el-button>
			<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
				@selection-change="handleSelectionChange">
				<el-table-column label="设备信息" width="260">
					<template slot-scope="scope">
						<div class="drawer_List">
							<p>编号：{{ scope.row.MechineName }}</p>
							<p>绑定时间：{{ scope.row.InsertTime.slice(0,19) }}</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column type="selection" width="55">
				</el-table-column>
			</el-table>
			<div style="margin-top: 20px">
				<el-button type="primary" @click="PutIn(1)" style="width:200px">投放</el-button>
			</div>
		</el-drawer>

		<el-drawer title="投放验光仪设备" :wrapperClosable="false" :visible.sync="ClasdrawerYgy" direction="rtl" size="23%"
			@closed="aaa()">
			<el-input v-model.trim="searchMname" placeholder="输入编号" type="text" style="width:200px;" />
			<el-button type="primary" class="btn" style="margin-left:10px" @click="searchMnameF(5)">搜索</el-button>
			<el-table ref="multipleTable" :data="tableDataYgy" tooltip-effect="dark" style="width: 100%"
				@selection-change="handleSelectionChange">
				<el-table-column label="设备信息" width="260">
					<template slot-scope="scope">
						<div class="drawer_List">
							<p>编号：{{ scope.row.MechineName }}</p>
							<p v-if="scope.row.InsertTime">绑定时间：{{ scope.row.InsertTime.slice(0,19) }}</p>
							<p v-else="scope.row.InsertTime">绑定时间:</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column type="selection" width="55">
				</el-table-column>
			</el-table>
			<div style="margin-top: 20px">
				<el-button type="primary" @click="PutIn(5)" style="width:200px">投放</el-button>
			</div>
		</el-drawer>

		<el-drawer title="投放音视频设备" :wrapperClosable="false" :visible.sync="ClasdrawerYsp" direction="rtl" size="23%"
			@closed="aaa()">
			<el-input v-model.trim="searchMname" placeholder="输入编号" type="text" style="width:200px;" />
			<el-button type="primary" class="btn" style="margin-left:10px" @click="searchMnameF(6)">搜索</el-button>
			<el-table ref="multipleTable" :data="tableDataYsp" tooltip-effect="dark" style="width: 100%"
				@selection-change="handleSelectionChange">
				<el-table-column label="设备信息" width="260">
					<template slot-scope="scope">
						<div class="drawer_List">
							<p>编号：{{ scope.row.MechineName }}</p>
							<p v-if="scope.row.InsertTime">绑定时间：{{ scope.row.InsertTime.slice(0,19) }}</p>
							<p v-else="scope.row.InsertTime">绑定时间:</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column type="selection" width="55">
				</el-table-column>
			</el-table>
			<div style="margin-top: 20px">
				<el-button type="primary" @click="PutIn(6)" style="width:200px">投放</el-button>
			</div>
		</el-drawer>

		<el-drawer title="投放训练仪设备" :wrapperClosable="false" :visible.sync="ClasdrawerXly" direction="rtl" size="23%"
			@closed="aaa()">
			<el-input v-model.trim="searchMname" placeholder="输入编号" type="text" style="width:200px;" />
			<el-button type="primary" class="btn" style="margin-left:10px" @click="searchMnameF(7)">搜索</el-button>
			<el-table ref="multipleTable" :data="tableDataXly" tooltip-effect="dark" style="width: 100%"
				@selection-change="handleSelectionChange">
				<el-table-column label="设备信息" width="260">
					<template slot-scope="scope">
						<div class="drawer_List">
							<p>编号：{{ scope.row.MechineName }}</p>
							<p v-if="scope.row.InsertTime">绑定时间：{{ scope.row.InsertTime.slice(0,19) }}</p>
							<p v-else="scope.row.InsertTime">绑定时间:</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column type="selection" width="55">
				</el-table-column>
			</el-table>
			<div style="margin-top: 20px">
				<el-button type="primary" @click="PutIn(7)" style="width:200px">投放</el-button>
			</div>
		</el-drawer>

		<el-drawer title="投放签到仪设备" :wrapperClosable="false" :visible.sync="ClasdrawerQdy" direction="rtl" size="23%"
			@closed="aaa()">
			<el-input v-model.trim="searchMname" placeholder="输入编号" type="text" style="width:200px;" />
			<el-button type="primary" class="btn" style="margin-left:10px" @click="searchMnameF(8)">搜索</el-button>
			<el-table ref="multipleTable" :data="tableDataQdy" tooltip-effect="dark" style="width: 100%"
				@selection-change="handleSelectionChange">
				<el-table-column label="设备信息" width="260">
					<template slot-scope="scope">
						<div class="drawer_List">
							<p>编号：{{ scope.row.MechineName }}</p>
							<p v-if="scope.row.InsertTime">绑定时间：{{ scope.row.InsertTime.slice(0,19) }}</p>
							<p v-else="scope.row.InsertTime">绑定时间:</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column type="selection" width="55">
				</el-table-column>
			</el-table>
			<div style="margin-top: 20px">
				<el-button type="primary" @click="PutIn(8)" style="width:200px">投放</el-button>
			</div>
		</el-drawer>

		<el-dialog title="设备回收" :visible.sync="Recycledialog" top="30px">
			<span>确认是否回收设备?</span>
			<br />
			<br />
			<span>
				<p style="color:#1890FF;display:inline">设备编号：{{MechineNames}}</p>
			</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="Recycledialog = false">取 消</el-button>
				<el-button type="primary" @click="affirm">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="选择设备类型" center :visible.sync="facilityDialog" width="500px">
			<div style="text-align: center;">
				设备类型：
				<el-select class="select" v-model="facilitySelect" size="medium" placeholder="请选择设备类型">
					<el-option v-for="item in facilityList" :key="item.value" :label="item.info" :value="item.info">
					</el-option>
				</el-select>
			</div>
			<div slot="footer">
				<el-button @click="facilityDialog = false">取 消</el-button>
				<el-button type="primary" @click="selectOk">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="安防设备投放" center :visible.sync="securityDialog" @closed="aaa()" width="800px"
			:close-on-click-modal="false">
			<div>
				<div
					style="display: grid;place-items: center;grid-template-rows: auto;grid-template-columns: repeat(2,1fr);">
					<div>
						投放位置：
						<el-autocomplete v-model="facilitySite" size="medium" :fetch-suggestions="querySearch"
							placeholder="请输入投放位置" style="width: 200px;"></el-autocomplete>
					</div>
					<div>
						检测类型：
						<el-select v-model="facilityTest" size="medium" placeholder="请选择检测类型" style="width: 200px;">
							<el-option v-for="item in facilityType" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div style="grid-column-end: span 2;margin-right: 295px;margin-top: 10px;">
						输入编号：
						<el-input v-model.trim="searchMname" placeholder="输入编号" type="text" style="width:200px;" />
						<el-button type="primary" class="btn" style="margin-left:10px"
							@click="searchMnameF(4)">搜索</el-button>
					</div>
				</div>
				<div>
					<el-table :data="securityData" @row-click="rowClick" border highlight-current-row
						empty-text="暂无可投放设备"
						style="max-height: 400px;width: 700px;overflow-y: auto;cursor: pointer;margin: 20px auto;">
						<el-table-column prop="MechineName" label="设备编号" align="center"></el-table-column>
						<el-table-column prop="InsertTime" label="绑定时间" align="center"> </el-table-column>
					</el-table>
				</div>
			</div>
			<div slot="footer">
				<el-button @click="securityDialog = false">取 消</el-button>
				<el-button type="primary" @click="securityOk">投 放</el-button>
			</div>
		</el-dialog>
		<el-dialog title="设置IP" :visible.sync="dialogFormVisible" top="50px" :close-on-click-modal="false">
			<el-form ref="articleForm" style="width:460px;margin:0 auto">
				<el-form-item label="请输入IP地址">
					<el-input v-model="ipAddr" style="width: 260px; float: left" placeholder="请输入IP地址" clearable>
					</el-input>
				</el-form-item>
				<el-form-item style="margin-top:5px">
					<el-button @click="dialogFormVisible = false">取消</el-button>
					<el-button type="primary" @click="changeIp">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		machineList,
		schoolBindMachine,
		schoolList,
		historySetList,
		saveCamera,
		machineTypeList,
		schoolRecoveryMachine,
		bindIp
	} from '@/api/agentApi.js'
	export default {
		data() {
			return {
				facilityInfos: [], // 学校设备列表
				securityData: [], //投放的安防设备
				facilityListSearch: [], //设备类型查询表
				facilityList: [], //设备类型表
				facilitySites: [], //设备历史投放位置
				facilityType: [{ //检测类型
					value: 1,
					label: '入校检测'
				}, {
					value: 2,
					label: '离校检测'
				}],
				facilitySelect: '', //选择投放的设备（视力，安防）
				facilitySite: '', //安防设备投放在学校的位置
				facilityTest: '', //选择的检测类型（入校，离校）
				securityFacilityId: '', //选择投放的安防设备id
				pagesize: 10, // 每页显示条目个数
				currpage: 1, // 初始页
				CountNum: 0, // 总条数
				loading: false, //加载框
				serchList: {}, //查询列表
				serchListCopy: {}, //查询列表
				schoolData: [], //学校集合
				stateList: [], //状态集合
				regionList: [], //地区集合
				SchID: '', // 学校ID
				SchName: '', // 学校名字
				tableData: [], // 投放设备列表(视力检查)
				tableDataYgy: [], // 投放设备列表(验光仪)
				tableDataYsp: [], // 投放设备列表(音视频)
				tableDataXly: [], // 投放设备列表(训练仪)
				tableDataQdy: [], // 投放设备列表(签到仪)
				tableDataXmqd: [], // 投放设备列表(校门签到)
				tableDataXmdkq: [], // 投放设备列表(校门读卡器)
				// tableDataSound:[],//投放音视频列表
				Clasdrawer: false, // 投放视力设备抽屉
				ClasdrawerYgy: false, // 投放 验光仪设备抽屉
				ClasdrawerYsp: false, // 投放 音视频设备抽屉
				ClasdrawerXly: false, // 投放 训练仪设备抽屉
				ClasdrawerQdy: false, // 投放 签到仪设备抽屉
				Recycledialog: false, // 回收设备弹窗
				ClasdrawerXmqd: false,
				ClasdrawerXmdkq: false,
				facilityDialog: false, //选择哪种设备弹框
				securityDialog: false, //投放安防设备弹框
				facilityID: '', // 设备ID
				MechineNames: '', // 设备编号
				MachineType: '', //设备类型
				checkBoxData: [], // 设备ID数组
				checkBoxDataEsb: [], // 设备ID数组判断2.0 还是3.0
				searchMname: '', //搜索设备
				yxList: [{
					value: '0',
					label: '全部'
				}, {
					value: '1',
					label: '运行中'
				}, {
					value: '2',
					label: '离线'
				}], // 设备运行状态
				readerId: null,
				dialogFormVisible: false,
				ipAddr: ''
			}
		},
		mounted() {
			this.SchID = this.$route.params.Schinfos // 学校ID赋值😜
			this.SchName = localStorage.getItem('SchName') // 取出本地存放的学校名称
			this.machineList({}, 1); // 获取学校设备列表
			// this.Unusedfacility(); // 空闲检测设备列表
			// this.UnusedfacilitySound(); // 空闲音视频检测设备列表
			// this.schoolList(); // 获取学校列表
			this.getMachineTypeList(); //获取设备类型列表
		},
		methods: {
			changeIp() {
				if (!this.ipAddr || !this.readerId) {
					message.error('请填写完整')
					return
				}
				const data = {
					ip: this.ipAddr,
					id: this.readerId
				}
				bindIp(data).then(res => {
					if (res.status.code == 1) {
						this.$message({
							message: '绑定成功',
							type: 'success'
						})
						this.ipAddr = '' // 清空
						this.dialogFormVisible = false; // 刷新
						this.machineList({}, 1); // 获取学校设备列表
					} else {
						this.$message.error(res.status.msg)
					}
				}).catch(err => {
					console.log(err)
				})
			},
			ipAdmin(row) {
				this.readerId = row.ID
				this.dialogFormVisible = true
			},
			reset() { //重置按钮
				this.serchList = {}
				this.serchListCopy = {}
				this.machineList({}, 1)
			},
			LaunchTime(row, column) { // 投放时间过滤
				return row.LaunchTime
				// return row.LaunchTime.slice(0, 16)
			},
			InsertTime(row, column) { // 绑定时间过滤
				return row.InsertTime
				// return row.InsertTime.slice(0, 16)
			},
			async getMachineTypeList() { //获取设备类型列表
				let res = await machineTypeList(); //请求接口

				this.facilityList = res.data //设备类型列表(排除全部) 和 音视频 训练议|| item.value == 6 || item.value == 7
				this.facilityListSearch = res.data //设备查询类型列表
			},
			search(obj) {
				this.serchListCopy = JSON.parse(JSON.stringify(obj)) //深拷贝
				this.machineList(obj, 1)
			},
			machineList(serchJson, currpage) { // 设备列表
				this.loading = true
				const data = serchJson
				data["page"] = currpage //	当前页数
				data["limit"] = this.pagesize // 每页条数
				data["schoolId"] = this.SchID // 学校ID
				data["jgType"] = 1 // 1 表示学校
				data["shopId"] = "" //

				machineList(data).then(res => {
					this.facilityInfos = res.data.List // 学校设备列表
					this.CountNum = parseInt(res.data.CountNum) // 总条数
					this.currpage = currpage //分页显示当前页
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},
			schoolList() { // 学校列表
				const data = {}
				data["page"] = 1 //	当前页数
				data["limit"] = 1000 // 每页条数
				schoolList(data).then(res => {
					this.schoolData = res.data.List.map((item, index) => {
						return Object.assign({}, {
							'schoolName': item.SchoolName
						}, {
							'id': item.ID
						})
						//console.log("data == " + JSON.stringify(this.schoolData))
					})
				}).catch(err => {
					console.log(err)
				})
			},
			Unusedfacility(mtype) { // 空闲设备列表
				const data = {
					page: 1, //	当前页数
					limit: 300, // 每页条数
					type: 2, // 空闲设备
					MachineType: mtype //设备类型 0-全部，1-测视力，2-音视频， 4-校门安防设备 ，5-验光仪， 6-音视频 ，7-训练议 8-签到议
				}
				machineList(data).then(res => {
					if (mtype == 1) {
						this.tableData = res.data.List // 空闲设备列表  视力检测
					} else if (mtype == 5) {
						this.tableDataYgy = res.data.List // 空闲设备列表 验光仪
					} else if (mtype == 6) {
						this.tableDataYsp = res.data.List // 空闲设备列表 音视频
					} else if (mtype == 7) {
						this.tableDataXly = res.data.List // 空闲设备列表  训练仪
					} else if (mtype == 8) {
						this.tableDataQdy = res.data.List // 空闲设备列表  签到仪
					} else if (mtype == 9) {
						this.tableDataXmqd = res.data.List // 空闲设备列表  签到仪
					} else if (mtype == 10) {
						this.tableDataXmdkq = res.data.List // 空闲设备列表  签到仪
					}
				}).catch(err => {
					console.log(err)
				})
			},
			BindSchool(row, column) { // 绑定学校
				return row.schoolName == null ? '暂无' : row.schoolName
			},
			Status(row, column) { // 状态转换
				return row.Status == '1' ? '运行中' : row.Status == '2' ? '停用中' : '故障中';
			},
			onlineStatus(row) { //在线状态过滤
				if (row.MachineType != 9) return ''
				return row.onlineStatus == '1' ? '运行中' : row.onlineStatus == '2' ? '离线' : '';
			},
			machineFilter(row, column) { //设备类型过滤
				switch (row.MachineType) { //设备类型 0-未设置，1-测视力，6-音视频，5-验光仪, 4-校门安防设备
					case '1':
						return '测视力设备'
						break;
					case '4':
						return '校门安防设备'
						break;
					case '5':
						return '验光仪设备'
						break;
					case '6':
						return '音视频设备'
						break;
					case '7':
						return '训练仪设备'
						break;
					case '8':
						return '签到仪设备'
						break;
					case '9':
						return '校门签到'
						break;
					case '10':
						return '校门读卡器'
						break;
					default:
						return '未设置'
				}
			},
			detail(index, row) { // 点击详情
				localStorage.setItem('Route', 'School') // 存跳转标识

				if (row.MachineType == 1) {
					this.$router.push({
						name: 'facilitydetail',
						params: {
							detailInfo: row.ID,
							type: row.MachineType,
							sbstatus: row.onlineStatus,
							schoolname: row.schoolName,
							InsertTime: row.InsertTime,
							LaunchTime: row.LaunchTime,
							MechineName: row.MechineName,
						}
					})
				} else if (row.MachineType >= 4) {
					this.$router.push({
						name: 'facilitydetailXyws',
						params: {
							detailInfo: row.ID,
							type: row.MachineType,
							sbstatus: row.onlineStatus,
							schoolname: row.schoolName,
							InsertTime: row.InsertTime,
							LaunchTime: row.LaunchTime,
							MechineName: row.MechineName,
						}
					})
				} else if (row.MachineType == 4) {
					this.$router.push({
						name: 'facilitydetailXyws',
						params: {
							detailInfo: row.ID,
							type: row.MachineType,
							sbstatus: row.onlineStatus,
							schoolname: row.schoolName,
							InsertTime: row.InsertTime,
							LaunchTime: row.LaunchTime,
							MechineName: row.MechineName,
						}
					})
				}

			},
			recycle(index, row) { // 点击回收
				this.Recycledialog = true; // 打开设备回收弹窗
				this.facilityID = row.ID; // 设备回收ID赋值
				this.MechineNames = row.MechineName; // 设备回收编号赋值
				this.MachineType = row.MachineType; // 设备回收设备类型赋值
			},
			affirm() { // 回收设备的确定
				const data = {
					schoolId: this.SchID, // 学校ID
					mechineId: this.facilityID, // 设备ID
					mechineType: this.MachineType //设备类型
				}
				schoolRecoveryMachine(data).then(res => {
					if (res.status.code == 1) {
						this.$message({
							message: '回收成功',
							type: 'success'
						})
						this.Recycledialog = false // 关闭弹窗
						this.machineList({}, 1) // 刷新学校设备列表
						// this.Unusedfacility() // 刷新空闲设备列表
						//       this.UnusedfacilitySound() // 刷新空闲设备列表
					} else {
						this.$message.error(res.status.msg)
					}
				}).catch(err => {
					console.log(err)
				})
			},
			selectOk() { //选择投放设备确认按钮
				this.facilityDialog = false; //关闭选择投放设备弹框
				this.checkBoxData = [];
				this.checkBoxDataEsb = [];
				switch (this.facilitySelect) {
					case '校门签到':
						this.ClasdrawerXmqd = true
						this.Unusedfacility(9);
						break;
					case '校门读卡器':
						this.ClasdrawerXmdkq = true
						this.Unusedfacility(10);
						break;
					case '视力检测设备':
						this.Clasdrawer = true; //打开投放视力设备弹框
						this.Unusedfacility(1); // 空闲检测设备列表
						break;
					case '校门安防设备':
						this.getSecurityData(); //获取未投放安防设备列表
						this.getFacilitySites(); //获取历史投放位置
						this.securityFacilityId = ''; //设置默认选中的安防设备id
						this.securityDialog = true; //打开投放安防设备弹框
						break;
					case '验光仪设备':
						this.ClasdrawerYgy = true; //打开投放 验光仪设备弹框
						this.Unusedfacility(5); // 空闲检测设备列表
						break;
					case '音视频设备':
						this.ClasdrawerYsp = true; //打开投放 音视频设备弹框
						this.Unusedfacility(6); // 空闲检测设备列表
						break;
					case '训练仪设备':
						this.ClasdrawerXly = true; //打开投放 训练仪设备弹框
						this.Unusedfacility(7); // 空闲检测设备列表
						break;
					case '签到仪设备':
						this.ClasdrawerQdy = true; //打开投放 训练仪设备弹框
						this.Unusedfacility(8); // 空闲检测设备列表
						break;

				}
			},
			async getFacilitySites() { //获取历史投放位置
				const data = {
					schoolId: this.SchID
				}
				let res = await historySetList(data); //请求历史投放位置接口
				this.facilitySites = res.data; //历史投放位置
			},
			async getSecurityData() { //获取未投放安防设备列表
				const data = {
					page: 1,
					limit: 9999,
					type: 2,
					MachineType: 4
				}
				let res = await machineList(data); //请求未投放安防设备列表接口
				this.securityData = res.data.List; //未投放安防设备列表
			},
			async securityOk() { //安防设备投放按钮
				if (!this.facilitySite) {
					this.$message.error('请填写投放的位置');
				} else if (!this.facilityTest) {
					this.$message.error('请选择投放检测的类型');
				} else if (!this.securityFacilityId) {
					this.$message.error('请选择投放的安防设备');
				} else {
					const data = {
						direction: this.facilityTest, //检测类型(入校 1, 离校 2)
						position: this.facilitySite, //投放位置
						schoolId: this.SchID, //学校id
						cameraId: this.securityFacilityId //安防设备id
					}
					let res = await saveCamera(data); //请求安防设备投放接口
					if (res.status.code == 1) {
						this.$message.success('投放成功');
						this.securityDialog = false; //关闭弹窗
						this.machineList({}, 1); // 获取学校设备列表
					} else {
						this.$message.error(res.status.msg);
					}
				}
			},
			rowClick(row) { //选中投放的安防设备
				this.securityFacilityId = row.ID; //保存选中的安防设备id
			},
			querySearch(queryString, cb) { //对选择安防设备投放位置进行输入建议过滤
				var results = [];
				if (!queryString) {
					this.facilitySites.forEach(item => {
						results.push({
							value: item.position
						});
					})
				} else {
					this.facilitySites.forEach(item => {
						if (item.position.indexOf(queryString) == 0) {
							results.push({
								value: item.position
							});
						}
					})
				}
				// 调用 callback 返回建议列表的数据
				cb(results);
			},
			handleSelectionChange(val) { // checkbox选项发生变化时
				//console.log(val);
				this.checkBoxData = val.map(item => item.ID)
				this.checkBoxDataEsb = val.map(item => item.e_sb)
				return false;
			},
			PutIn(mtype) { // 点击投放
				const Newarr = this.checkBoxData.join('-') //join 数组变成 字符串 用"-"链接
				const NewarrEsb = this.checkBoxDataEsb.join('-')

				if (Newarr == "") {
					this.$message({
						message: '请选择要投放的设备',
						type: 'error'
					})
					return false;
				}

				const data = {
					schoolId: this.SchID, //	学校ID
					mechineIdStr: Newarr, // 	设备ID
					mechineType: mtype, // 	设备类型
					mechineIdStrEsb: NewarrEsb //来判断是2.0 还是3.0
				}
				schoolBindMachine(data).then(res => {
					if (res.status.code == 1) {
						this.$message({
							message: '投放成功',
							type: 'success'
						})
						this.machineList({}, 1) // 刷新
						// this.Unusedfacility() // 刷新
						//       this.UnusedfacilitySound() // 刷新
						if (mtype == 1) {
							this.Clasdrawer = false; // 刷新
						} else if (mtype == 5) {
							this.ClasdrawerYgy = false; // 刷新
						} else if (mtype == 6) {
							this.ClasdrawerYsp = false; // 刷新
						} else if (mtype == 7) {
							this.ClasdrawerXly = false; // 刷新
						} else if (mtype == 8) {
							this.ClasdrawerQdy = false; // 刷新
						} else if (mtype == 9) {
							this.ClasdrawerXmqd = false; // 刷新
						} else if (mtype == 10) {
							this.ClasdrawerXmdkq = false; // 刷新
						}

						this.Sounddrawer = false; // 刷新
					} else {
						this.$message.error(res.status.msg)
					}
				}).catch(err => {
					console.log(err)
				})
			},
			handleCurrentChange(val) { // 分页
				// console.log(this.serchList)

				// console.log(this.serchListCopy)
				this.machineList(this.serchListCopy, val)
				//this.currpage = val
			},
			searchMnameF(type) {
				// if (this.searchMname == "") {
				//   return false;
				// }

				if (type == 1 || type == 5 || type == 6 || type == 7 || type == 4 || type == 8) {
					const data = {
						page: 1, //	当前页数
						limit: 300, // 每页条数
						type: 2, // 空闲设备
						MachineType: type, //设备类型 0-全部，1-测视力，6-音视频， 4-校门安防设备  5验光仪 7 训练仪 8签到仪
						searchMname: this.searchMname
					}
					machineList(data).then(res => {
						// console.log(res);
						if (type == 1) {
							this.tableData = res.data.List // 空闲设备列表
						} else if (type == 5) {
							this.tableDataYgy = res.data.List // 空闲设备列表
						} else if (type == 6) {
							this.tableDataYsp = res.data.List // 空闲设备列表
						} else if (type == 7) {
							this.tableDataXly = res.data.List // 空闲设备列表
						} else if (type == 4) {
							this.securityData = res.data.List; //未投放安防设备列表
						} else if (type == 8) {
							this.tableDataQdy = res.data.List // 空闲设备列表
						}
					}).catch(err => {
						console.log(err)
					})
				}
			},
			aaa() { //清空输入框
				this.searchMname = '';
			}
		}
	}
</script>

<style lang="scss" scoped>
	/* 用来设置当前页面element全局table 选中某行时的背景色*/
	/deep/.el-table__body tr.current-row>td {
		background-color: #67C23A;
		color: #FFFFFF;
	}

	#body {
		// height: 100%;
		background: #f0f2f5;
	}

	.fatherBox {
		width: 98%;
		// height: 800px;
		display: flex;
		background: white;
		margin: 0 auto;
		flex-direction: column;
		justify-content: center;

		.headerBox {
			display: flex;
			height: 52px;
			width: 98%;
			align-items: center;
			margin-left: 25px;
			justify-content: space-between;
			border-bottom: 2px solid #e9e9e9;

			.headerinfos {
				display: flex;

				p {
					color: burlywood;
				}

				p:nth-child(3) {
					color: black;
				}
			}

			p {
				font-size: 16px;
				font-family: 萍方-简;
				margin-left: 10px;
			}

			.btn {
				display: flex;
				align-items: center;
				width: 109px;
				height: 32px;
				opacity: 1;
				border-radius: 2px;
				margin-right: 25px;
			}
		}

		.query {
			width: 98%;
			align-items: center;
			display: flex;
			justify-content: space-between;
			padding-top: 20px;
			padding-left: 32px;

			.queryLeft {
				width: 75%;
				display: flex;

				div {
					width: 22%;
					text-align: left;

					.input,
					.select {
						width: 60%;
					}
				}
			}
		}
	}

	.stulist {
		color: #8C8C8C;
		cursor: pointer;
		// margin: 0 3px 0 32px;
	}

	.drawer_List {
		p:nth-child(1) {
			font-size: 15px;
			color: #000000;
			font-family: SFUIText-Regular;
		}

		p:nth-child(2) {
			font-size: 13px;
			color: rgba(0, 0, 0, 0.35);
			font-family: Source Han Sans SC;
		}
	}

	//     /deep/.el-drawer__body {
	//     overflow: auto;
	//     /* overflow-x: auto; */
	// }

	// /deep/.el-drawer__container ::-webkit-scrollbar{
	//     display: none;
	// }
	/deep/ .el-drawer.rtl {
		overflow: scroll
	}

	/deep/ .el-drawer.ltr {
		overflow: scroll
	}
</style>